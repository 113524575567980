import React from "react";
import { FaBoxOpen, FaUsers, FaChartBar, FaDollarSign } from "react-icons/fa";
import "./Dashboard.css";

// Create a CSS file for styling

// Sample Dashboard Component
const Dashboard = () => {
  return (
    <div className="dashboard-page">
      <h2>Shop Dashboard</h2>
      <p>
        Welcome to your Dashboard! Here you can see an overview of your shop's
        performance, sales, and customer activity.
      </p>
      <br />
      <br />
      <br />

      <div className="dashboard-stats">
        <div className="stat1-card">
          <FaBoxOpen size={40} />
          <h3>Active Offers</h3>
          <p>5</p> {/* Replace with dynamic data */}
        </div>
        <div className="stat1-card">
          <FaUsers size={40} />
          <h3>Total Customers</h3>
          <p>350</p> {/* Replace with dynamic data */}
        </div>
        <div className="stat1-card">
          <FaChartBar size={40} />
          <h3>Sales This Month</h3>
          <p>$4,500</p> {/* Replace with dynamic data */}
        </div>
        <div className="stat1-card">
          <FaDollarSign size={40} />
          <h3>Total Revenue</h3>
          <p>$15,000</p> {/* Replace with dynamic data */}
        </div>
      </div>

      <div className="dashboard-visuals">
        <h3>Performance Analytics</h3>
        {/* You can use charting libraries like Chart.js, Recharts, or ApexCharts here */}
        <div className="chart-placeholder">
          <p>Sales Chart Placeholder</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
