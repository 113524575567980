import React from "react";
import {
  FaTachometerAlt,
  FaTags,
  FaClipboardList,
  FaChartLine,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Main.css";

// Main Shopkeeper Dashboard Page
const Main = () => {
  return (
    <div className="shopkeeper-dashboard">
      <h1>Welcome to Your Shopkeeper Dashboard</h1>
      <p className="intro-text">
        Manage your offers, track orders, and get detailed analytics in one
        place.
      </p>

      <div className="dashboard-buttons">
        <Link to="/dashboard" className="dashboard-btn">
          <FaTachometerAlt size={30} />
          <span>Dashboard</span>
        </Link>
        <Link to="/offers" className="dashboard-btn">
          <FaTags size={30} />
          <span>Offers</span>
        </Link>
        <Link to="/orderqueue" className="dashboard-btn">
          <FaClipboardList size={30} />
          <span>Order Queue</span>
        </Link>
        <Link to="/analytics" className="dashboard-btn">
          <FaChartLine size={30} />
          <span>Analytics</span>
        </Link>
        <Link to="/pendingapproval" className="dashboard-btn">
          <FaChartLine size={30} />
          <span>Pending Approval</span>
        </Link>
      </div>
    </div>
  );
};

export default Main;
