import React from "react";
import "./Test3.css";

const Test3 = () => {
  return (
    <div>
      <div className="heading-test3">Heyy</div>
      
      <div className="heading-test3">lorem34</div>
    </div>
  );
};

export default Test3;
