import React, { useState } from "react";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import "./Offers.css";

const Offers = () => {
  const [offers, setOffers] = useState([
    { id: 1, name: "50% off on Pizza", discount: "50%", expires: "Dec 31, 2024" },
    { id: 2, name: "Buy 1 Get 1 Free", discount: "100%", expires: "Nov 30, 2024" },
  ]);

  const handleAddOffer = () => {
    console.log("Add Offer");
  };

  const handleEditOffer = (id) => {
    console.log("Edit Offer", id);
  };

  const handleDeleteOffer = (id) => {
    setOffers(offers.filter((offer) => offer.id !== id));
  };

  return (
    <div className="offers-page">
      <h2>Manage Your Offers</h2>
      <button className="add-offer-btn" onClick={handleAddOffer}>
        <FaPlus size={20} /> Add New Offer
      </button>

      <div className="offers-list">
        {offers.map((offer) => (
          <div className="offer-card" key={offer.id}>
            <h3>{offer.name}</h3>
            <p>Discount: {offer.discount}</p>
            <p>Expires: {offer.expires}</p>
            <div className="offer-actions">
              <button onClick={() => handleEditOffer(offer.id)}>
                <FaEdit /> Edit
              </button>
              <button onClick={() => handleDeleteOffer(offer.id)}>
                <FaTrash /> Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Offers;
