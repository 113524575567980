import { QRCodeCanvas } from "qrcode.react";
import React, { useState } from "react";
import "./Upi.css";

const Upi = () => {
  const [amount, setAmount] = useState();
  const [upiLink, setUpiLink] = useState("");
  const [showQR, setShowQR] = useState(false);

  const upiId = "raghav.mitt9@oksbi"; // Replace with your UPI ID
  const name = "Raghav Mittal"; // Replace with your name

  const generateUpiLink = () => {
    if (amount && !isNaN(Number(amount)) && Number(amount) > 0) {
      const generatedLink = `upi://pay?pa=${upiId}&pn=${encodeURIComponent(
        name
      )}&am=${amount}&cu=INR`;
      setUpiLink(generatedLink);
      setShowQR(true);

      // Open the UPI link in the user's UPI app
      window.location.href = generatedLink;

      // Simulate payment verification
      setTimeout(async () => {
        const verified = window.confirm("Did you complete the payment?");
        if (verified) {
          alert("Payment verified successfully!");

          // Send payment data to the backend
          const userName = localStorage.getItem("name"); // Retrieve name from localStorage
          try {
            const response = await fetch(
              `${process.env.REACT_APP_SERVER_URL}/api/shopkeeper/`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ name: userName, amount }),
              }
            );

            const result = await response.json();
            if (response.ok) {
              console.log("Payment data saved:", result);
            } else {
              console.error("Error saving payment data:", result.message);
            }
          } catch (error) {
            console.error("Error in saving UPI data:", error);
          }
        } else {
          alert("Payment not verified. Please try again.");
        }
      }, 5000); // Wait for 5 seconds before asking for verification
    } else {
      alert("Please enter a valid amount.");
    }
  };

  const handleAmountClick = (selectedAmount: string) => {
    setAmount(selectedAmount);
  };

  return (
    <div className="payment-container">
      <h2>Pay via UPI</h2>
      <div className="amount-buttons">
        {["50", "100", "500", "1000"].map((value) => (
          <button
            key={value}
            className={`amount-btn ${amount === value ? "active" : ""}`}
            onClick={() => handleAmountClick(value)}
          >
            ₹{value}
          </button>
        ))}
      </div>
      <div className="input-container">
        <span className="currency-symbol">₹</span>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="amount-input"
          placeholder="Enter amount"
        />
      </div>
      <button onClick={generateUpiLink} className="pay-button">
        Pay Now
      </button>
      {showQR && (
        <div className="qr-container">
          <p>Scan the QR code to pay:</p>
          <QRCodeCanvas value={upiLink} size={200} />
        </div>
      )}
    </div>
  );
};

export default Upi;
