import axios from "axios";
import React, { useEffect, useState } from "react";
import "./MyOrders.css";

const MyOrders = () => {
  const [latestOrder, setLatestOrder] = useState(null);
  const localStorageName = localStorage.getItem("name"); // Fetch name from localStorage

  useEffect(() => {
    // Fetch user-specific orders
    const fetchMyOrders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/shopkeeper/orders` // Replace with your actual endpoint
        );

        // Filter orders by name matching localStorage and sort by creation date
        const myOrders = response.data.data
          .filter((order) => order.name === localStorageName)
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Latest first

        // Set the latest order
        setLatestOrder(myOrders[0] || null);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchMyOrders();
  }, [localStorageName]);

  return (
    <div className="my-orders-wrapper">
      <h2>My Latest Order</h2>
      {latestOrder ? (
        <div
          className={`my-order-item ${
            latestOrder.orderStatus === "completed" ? "completed" : ""
          }`}
        >
          <p>
            <strong>Name:</strong> {latestOrder.name}
          </p>
          <p>
            <strong>Token:</strong> {latestOrder.token}
          </p>
        </div>
      ) : (
        <p>No orders found.</p>
      )}
    </div>
  );
};

export default MyOrders;
