import React from "react";

const Analytics = () => {
  return (
    <div className="analytics-page">
      <h2>Analytics</h2>
      <p>View detailed analytics to understand your shop's performance.</p>
      {/* Add charts or tables for sales, customer trends, and other analytics */}
    </div>
  );
};

export default Analytics;
