import React, { useEffect } from "react";
import "./Admin.css";

const Admin = () => {
  useEffect(() => {
    // Initial data fetch
    fetchData();

    // Add event listener for the refresh button
    const refreshButton = document.getElementById("refreshButton");
    if (refreshButton) {
      refreshButton.addEventListener("click", fetchData);
    }

    // Cleanup function
    return () => {
      if (refreshButton) {
        refreshButton.removeEventListener("click", fetchData);
      }
    };
  }, []);

  const fetchData = () => {
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    const endpoints = [
      { url: `${baseUrl}/api/admin/users/count`, element: "userCount" },
      {
        url: `${baseUrl}/api/admin/requests/completed`,
        element: "completedRequests",
      },
      {
        url: `${baseUrl}/api/admin/requests/active`,
        element: "activeRequests",
      },
      { url: `${baseUrl}/api/admin/users/premium`, element: "premiumUsers" },
    ];

    endpoints.forEach(({ url, element }) => {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          const countElement = document.getElementById(element);
          if (countElement) {
            countElement.textContent = data.count;
          }
        })
        .catch((err) => console.error(`Error fetching ${element}:`, err));
    });
  };

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      <button id="refreshButton" className="refresh-button">
        Refresh Data
      </button>
      <div className="dashboard-stats">
        <div className="stat-card">
          <h2>Total Users</h2>
          <p id="userCount">-</p>
        </div>
        <div className="stat-card">
          <h2>Completed Requests</h2>
          <p id="completedRequests">-</p>
        </div>
        <div className="stat-card">
          <h2>Active Requests</h2>
          <p id="activeRequests">-</p>
        </div>
        <div className="stat-card">
          <h2>Premium Users</h2>
          <p id="premiumUsers">-</p>
        </div>
      </div>
    </div>
  );
};

export default Admin;
