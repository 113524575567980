import axios from "axios";
import React, { useEffect, useState } from "react";
import "./PendingApproval.css";

const PendingApproval = () => {
  const [pendingRequests, setPendingRequests] = useState([]);

  useEffect(() => {
    // Fetch pending requests from the backend
    const fetchPendingRequests = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/shopkeeper/pending`
        );
        setPendingRequests(response.data.data); // Assuming 'data' is an array of requests
      } catch (error) {
        console.error("Error fetching pending requests:", error);
      }
    };

    fetchPendingRequests();
  }, []);

  // Function to approve a request
  const approveRequest = async (id) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/shopkeeper/${id}/approve`
      );
      alert(response.data.message); // Show success message
      setPendingRequests(
        pendingRequests.filter((request) => request._id !== id)
      ); // Remove approved request from UI
    } catch (error) {
      console.error("Error approving request:", error);
      alert("Failed to approve request");
    }
  };

  // Function to reject a request (optional)
  const rejectRequest = async (id) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/shopkeeper/${id}/reject`
      );
      alert(response.data.message); // Show success message
      setPendingRequests(
        pendingRequests.filter((request) => request._id !== id)
      ); // Remove rejected request from UI
    } catch (error) {
      console.error("Error rejecting request:", error);
      alert("Failed to reject request");
    }
  };

  return (
    <div className="pending-approval-wrapper">
      <h2 className="pending-approval-title">Pending UPI Requests</h2>
      {pendingRequests.length === 0 ? (
        <p className="pending-approval-text no-requests">No pending requests</p>
      ) : (
        <ul>
          {pendingRequests.map((request) => (
            <li key={request._id} className="pending-approval-item">
              <p className="pending-approval-text">
                <strong>Name:</strong> {request.name}
              </p>
              <p className="pending-approval-text">
                <strong>Amount:</strong> ₹{request.amount}
              </p>
              <div className="pending-approval-actions">
                <button
                  className="pending-approval-button pending-approval-button-approve"
                  onClick={() => approveRequest(request._id)}
                >
                  Approve
                </button>
                <button
                  className="pending-approval-button pending-approval-button-reject"
                  onClick={() => rejectRequest(request._id)}
                >
                  Reject
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PendingApproval;
