import axios from "axios";
import React, { useEffect, useState } from "react";
import "./OrderQueue.css";

const OrderQueue = () => {
  const [orderQueue, setOrderQueue] = useState([]);

  useEffect(() => {
    const fetchOrderQueue = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/api/shopkeeper/order-queue`
        );
        setOrderQueue(response.data.data);
      } catch (error) {
        console.error("Error fetching order queue:", error);
      }
    };

    fetchOrderQueue();
  }, []);

  const completeOrder = async (id) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/shopkeeper/${id}/complete`
      );
      alert(response.data.message); // Show success message
      setOrderQueue(orderQueue.filter((order) => order._id !== id)); // Remove completed order from UI
    } catch (error) {
      console.error("Error marking order as completed:", error);
      alert("Failed to mark order as completed");
    }
  };

  return (
    <div className="order-queue-wrapper">
      <h2>Order Queue</h2>
      {orderQueue.length === 0 ? (
        <p>No orders to display</p>
      ) : (
        <ul style={{ listStyle: "none" }}>
          {orderQueue.map((order) => (
            <li key={order._id} className="order-item">
              <p>
                <strong>Token:</strong> {order.token}
              </p>
              <p>
                <strong>Name:</strong> {order.name}
              </p>
              <p>
                <strong>Amount:</strong> ₹{order.amount}
              </p>
              <p>
                <strong>Order Status:</strong> {order.orderStatus}
              </p>
              <button
                className="complete-button"
                onClick={() => completeOrder(order._id)}
              >
                Complete
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default OrderQueue;
