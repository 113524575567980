import "./App.css";
import AssignmentRequest from "./components/AssignmentRequest";
import Chatbutton from "./components/Buttons/Chatbutton";
import Chatroom from "./components/Chatroom";
import ContactUs from "./components/ContactUs";
// Add this
import Cover from "./components/Cover";
import Homepage from "./components/Homepage";
import Inbox from "./components/Inbox";
import L from "./components/L";
import MinimalistMobileMenu from "./components/MinimalistMobileMenu";
import MobileCover from "./components/MobileCover";
import MobileHomepage from "./components/MobileHomepage";
import Privacy from "./components/Privacy";
import ScrollToTop from "./components/ScrollToTop";
import Subscription from "./components/Subscription";
import TalentSkillBoard from "./components/TalentSkillBoard";
import TC from "./components/TermsAndServices";
import TestSocket from "./components/TestSocket";
import Upi from "./components/Upi";
import Vide from "./components/Video";
import WelcomePopups from "./components/WelcomePopups";
import Admin from "./components/admin/Admin.js";
import Blog from "./components/blogs/Blog";
import Blog2 from "./components/blogs/Blog2";
import Blog3 from "./components/blogs/Blog3";
import Blog4 from "./components/blogs/Blog4";
import Blog5 from "./components/blogs/Blog5";
import Blog7 from "./components/blogs/Blog7";
import Test_B from "./components/blogs/Test_B";
import Test from "./components/blogs/Tests";
import Analytics from "./components/shopkeeper/Analytics.js";
import Dashboard from "./components/shopkeeper/Dashboard.js";
import Main from "./components/shopkeeper/Main.js";
import MyOrders from "./components/shopkeeper/MyOrders.js";
import Offers from "./components/shopkeeper/Offers.js";
import OrderQueue from "./components/shopkeeper/OrderQueue.js";
import PendingApproval from "./components/shopkeeper/PendingApproval.js";
import MapTest from "./components/tests/MapTest.js";
import RequestList from "./components/tests/RequestList.js";
import RequestVincity from "./components/tests/RequestVincity.js";
import Test1 from "./components/tests/Test1.js";
import Test2 from "./components/tests/Test2.js";
import Test3 from "./components/tests/Test3.js";
import { jwtDecode } from "jwt-decode";
import { Video } from "lucide-react";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// import AcademicRequestModal from './components/AcademicRequestModal';

const App = () => {
  // const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // Determine if the device is mobile based on screen width
  const isMobile = window.innerWidth <= 768; // Example threshold for mobile

  useEffect(() => {
    ReactGA.initialize("G-830YGE01HC"); // Use your Measurement ID here
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            exact
            path="/"
            element={isMobile ? <MobileCover /> : <Vide />}
          />
          <Route exact path="/M" element={<MobileCover />} />
          <Route exact path="/l" element={<L />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog1" element={<Blog />} />
          <Route exact path="/homepage" element={<Homepage />} />
          <Route exact path="/blog2" element={<Blog2 />} />
          <Route exact path="/blog3" element={<Blog3 />} />
          <Route exact path="/blog4" element={<Blog4 />} />
          <Route exact path="/blog5" element={<Blog5 />} />
          <Route exact path="/blog7" element={<Blog7 />} />
          <Route exact path="/test" element={<Test />} />
          <Route exact path="/testb" element={<Test_B />} />
          <Route exact path="/mhpg" element={<MobileHomepage />} />
          <Route exact path="/inbox" element={<Inbox />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/offers" element={<Offers />} />
          <Route exact path="/analytics" element={<Analytics />} />
          <Route exact path="/pendingapproval" element={<PendingApproval />} />
          <Route exact path="/main" element={<Main />} />
          <Route exact path="/orderqueue" element={<OrderQueue />} />
          <Route exact path="/myorders" element={<MyOrders />} />

          <Route
            exact
            path="/assignmentrequests"
            element={<AssignmentRequest />}
          />
          {/* <Route
            exact
            path="/talentskillboard"
            element={<TalentSkillBoard />}
          /> */}
          <Route exact path="/tc" element={<TC />} />
          {/* <Route exact path="/ac" element={<AcademicRequestModal />} /> */}
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/subscription" element={<Subscription />} />
          {/* <Route exact path="/blog4" element={<BlogPostPage />} /> */}
          {/* <Route exact path="/chatbutton" element={<Chatbutton />} /> */}
          <Route exact path="/chatroom" element={<Chatroom />} />
          <Route exact path="/maptest" element={<MapTest />} />
          <Route exact path="/test2" element={<Test2 />} />
          <Route exact path="/test3" element={<Test3 />} />
          <Route exact path="/Upi" element={<Upi />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="/requestvincity" element={<RequestVincity />} />
          <Route exact path="/requestlist" element={<RequestList />} />
          {/* <Route exact path="/testsocket" element={<TestSocket />} /> */}
          {/* <Route exact path="/wc" element={<WelcomePopups />} /> */}
          {/* <Route exact path="/menu" element={<MinimalistMobileMenu />} /> */}
          {/* <Route exact path="/vido2" element={<Vide />} /> */}
          {/* <Route exact path="/contact" element={<ContactUs />} /> */}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
